import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import DragIcon from '../../assets/images/drag.svg';

import styles from './DragTooltip.module.scss';

const DragTooltip = ({ show }) => (
    <div className={cx(styles.root, show && styles.show)}>
        <img alt="Drag Icon" className={styles.image} src={DragIcon} />
        Drag To View
    </div>
);

DragTooltip.propTypes = {
    show: PropTypes.bool.isRequired,
};

export default DragTooltip;
