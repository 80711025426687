import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

const rootDiv = document.createElement('div');
rootDiv.style.height = '100%';
document.body.appendChild(rootDiv);

const root = ReactDOM.createRoot(rootDiv);
root.render(<App />);
