import React, { useEffect, useRef } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Hotspot from '../Hotspot/Hotspot';

import styles from './FrameViewer.module.scss';

let dragging = false;
let startMouseX = 0;
let startFrameIndex = 0;
let clipboardTimeout = null;
let firstDrag = true;

const FrameViewer = ({
    frame,
    totalFrames,
    frameIndex,
    setFrameIndex,
    adminMode,
    setAdminText,
    hotspots,
    activeHotspot,
    clickHotspot,
    hideDragTooltip,
}) => {
    const imageRef = useRef(null);
    const containerRef = useRef(null);
    const frameIndexRef = useRef(frameIndex);
    const setFrameIndexRef = data => {
        frameIndexRef.current = data;
        setFrameIndex(data);
    };
    useEffect(() => {
        function handlekeydownEvent(event) {
            const { key, keyCode } = event;
            if (key === 'ArrowLeft' || keyCode === 37) {
                // previous
                setFrameIndexRef(
                    frameIndexRef.current - 1 >= 0
                        ? frameIndexRef.current - 1
                        : totalFrames - 1
                );
            } else if (key === 'ArrowRight' || keyCode === 39) {
                // next
                setFrameIndexRef(
                    frameIndexRef.current + 1 < totalFrames
                        ? frameIndexRef.current + 1
                        : 0
                );
            }
        }

        function onMouseDown(event) {
            event.preventDefault();
            startMouseX = !!event.touches
                ? event.touches[0].pageX
                : event.pageX;
            startFrameIndex = frameIndexRef.current;
            dragging = true;
        }

        function onMouseDownImage(event) {
            let x = parseFloat(
                ((event.offsetX / imageRef.current.clientWidth) * 100).toFixed(
                    4
                )
            );
            let y = parseFloat(
                ((event.offsetY / imageRef.current.clientHeight) * 100).toFixed(
                    4
                )
            );
            let textArea = document.createElement('textarea');
            textArea.value = `${x},${y}`;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand('Copy');
            textArea.remove();
            setAdminText(`Copied to clipboard: ${textArea.value}`);
            if (clipboardTimeout) {
                clearTimeout(clipboardTimeout);
                clipboardTimeout = null;
            }
            clipboardTimeout = setTimeout(() => {
                setAdminText(null);
                clipboardTimeout = null;
            }, 3000);
        }

        function onMouseUp(event) {
            dragging = false;
        }

        function onMouseMove(event) {
            if (!dragging) {
                return;
            }
            if (firstDrag) {
                firstDrag = false;
                hideDragTooltip();
            }
            const offsetX =
                (!!event.touches ? event.touches[0].pageX : event.pageX) -
                (startMouseX || 0);
            const indexPerPixel =
                (totalFrames / containerRef.current.offsetWidth) * 1.5;
            let offsetIndex = Math.round(offsetX * indexPerPixel) % totalFrames;
            let newIndex = (startFrameIndex + offsetIndex) % totalFrames;
            newIndex =
                newIndex < 0 ? totalFrames - Math.abs(newIndex) : newIndex;
            if (newIndex !== frameIndexRef.current) {
                setFrameIndexRef(newIndex);
            }
        }

        document.addEventListener('keyup', handlekeydownEvent);
        if (adminMode) {
            imageRef.current.addEventListener('mousedown', onMouseDownImage);
        }
        imageRef.current.addEventListener('mousedown', onMouseDown);
        imageRef.current.addEventListener('touchstart', onMouseDown);
        document.addEventListener('mouseup', onMouseUp);
        document.addEventListener('touchend', onMouseUp);
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('touchmove', onMouseMove);
        return () => {
            document.removeEventListener('keyup', handlekeydownEvent);
            if (adminMode) {
                imageRef.current.removeEventListener(
                    'mousedown',
                    onMouseDownImage
                );
            }
            imageRef.current.removeEventListener('mousedown', onMouseDown);
            imageRef.current.removeEventListener('touchstart', onMouseDown);
            document.removeEventListener('mouseup', onMouseUp);
            document.removeEventListener('touchend', onMouseUp);
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('touchmove', onMouseMove);
        };
    }, []);
    return (
        <div className={styles.root} ref={containerRef}>
            {hotspots
                .filter(hotspot => hotspot.frames.indexOf(frameIndex) !== -1)
                .map((hotspot, i) => {
                    let [x, y] = hotspot.position
                        .split(',')
                        .map(p => parseFloat(p.trim()));
                    return (
                        <Hotspot
                            key={i}
                            onClick={e => {
                                e.stopPropagation();
                                console.log('test');
                                clickHotspot(hotspot);
                            }}
                            active={activeHotspot?.id === hotspot.id}
                            x={x}
                            y={y}
                        />
                    );
                })}
            <img ref={imageRef} src={frame.src} className={cx(styles.frame)} />
        </div>
    );
};

FrameViewer.propTypes = {
    frame: PropTypes.object.isRequired,
    frameIndex: PropTypes.number.isRequired,
    totalFrames: PropTypes.number.isRequired,
    setFrameIndex: PropTypes.func.isRequired,
    adminMode: PropTypes.bool.isRequired,
    setAdminText: PropTypes.func.isRequired,
    hotspots: PropTypes.array.isRequired,
    activeHotspot: PropTypes.object,
    clickHotspot: PropTypes.func.isRequired,
    hideDragTooltip: PropTypes.func.isRequired,
};

export default FrameViewer;
