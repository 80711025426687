import React from 'react';
import PropTypes from 'prop-types';

import styles from './QuickView.module.scss';

const QuickView = ({ items, onChooseItem }) => (
    <div className={styles.root}>
        <h3 className={styles.title}>Hotspots</h3>
        <div className={styles.box}>
            {items.map((item, i) => (
                <div className={styles.item} key={i}>
                    <button
                        onClick={() =>
                            onChooseItem(item.frameIndex, item.hotspotIndex)
                        }
                        title={item.name}
                        className={styles.button}
                        style={{ backgroundImage: `url(${item.image})` }}
                    />
                    <div className={styles.number}>{i + 1}</div>
                </div>
            ))}
        </div>
    </div>
);

QuickView.propTypes = {
    items: PropTypes.array.isRequired,
    onChooseItem: PropTypes.func.isRequired,
};

export default QuickView;
