import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './Button.module.scss';

console.log('styles', styles);

const Button = ({ className, children, onClick }) => (
    <button className={cx(styles.button, className)} onClick={onClick}>
        {children}
    </button>
);

Button.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    children: PropTypes.string.isRequired,
};

export default Button;
