import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ReactMarkdown from 'react-markdown';

import DownloadIcon from '../../assets/images/download.svg';

import styles from './HotspotDetails.module.scss';

const HotspotDetails = ({
    show,
    title,
    body,
    resources,
    images,
    links,
    onClose,
    footnotes,
    thumbnail,
}) => {
    let [index, setIndex] = useState(0);
    let type = images[index]?.file.contentType ?? '';
    return (
        <div className={cx(styles.root, show && styles.show)} onClick={onClose}>
            <div className={styles.box} onClick={e => e.stopPropagation()}>
                <div className={styles.close} onClick={onClose} />
                <div className={styles.title}>{title}</div>
                <ReactMarkdown
                    source={body}
                    escapeHtml={false}
                    linkTarget={'_blank'}
                    className={styles.body}
                />
                {(resources.length > 0 || links.length > 0) && (
                    <div className={styles.resources}>
                        <div className={styles.resourcesTitle}>Resources</div>
                        {resources.map((resource, i) => (
                            <a
                                className={styles.resource}
                                key={i}
                                href={resource.file.url}
                                rel="noreferrer"
                                target={'_blank'}
                            >
                                {resource.title}{' '}
                                <img alt="Download Icon" src={DownloadIcon} />
                            </a>
                        ))}
                        {links.map((link, i) => (
                            <a
                                key={i}
                                className={styles.resource}
                                href={link.url}
                                rel="noreferrer"
                                target={'_blank'}
                            >
                                {link.title}
                            </a>
                        ))}
                    </div>
                )}
                {images.length > 0 && (
                    <div className={styles.mediaContainer}>
                        {index > 0 && (
                            <button
                                className={styles.arrowLeft}
                                onClick={() => setIndex(index - 1)}
                            />
                        )}
                        {type.startsWith('image') && (
                            <img
                                className={styles.media}
                                src={images[index].file.url}
                            />
                        )}
                        {type.startsWith('video') && (
                            <video
                                className={styles.media}
                                src={images[index].file.url}
                                preload={'metadata'}
                                poster={thumbnail?.file?.url ?? null}
                                controls
                            />
                        )}
                        {index < images.length - 1 && (
                            <button
                                className={styles.arrowRight}
                                onClick={() => setIndex(index + 1)}
                            />
                        )}
                        {images[index].description && (
                            <div className={styles.caption}>
                                {images[index].description}
                            </div>
                        )}
                    </div>
                )}
                {footnotes.length > 0 &&
                    footnotes.map((footnote, i) => (
                        <div key={i} className={styles.footnote}>
                            <div className={styles.footnoteNumber}>{i + 1}</div>
                            <div className={styles.footnoteText}>
                                {footnote}
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

HotspotDetails.propTypes = {
    show: PropTypes.bool.isRequired,
    thumbnail: PropTypes.object,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    images: PropTypes.array.isRequired,
    resources: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default HotspotDetails;
