import React, { useEffect, useRef, useState } from 'react';
import './styles/theme.scss';
import styles from './App.module.scss';
import Button from './components/Button/Button';
import Modal from './components/Modal/Modal';
import QuickView from './components/QuickView/QuickView';
import DragTooltip from './components/DragTooltip/DragTooltip';
import HotspotDetails from './components/HotspotDetails/HotspotDetails';
import { getProduct } from './services/ContentfulService';
import FrameViewer from './components/FrameViewer/FrameViewer';
import AdminMode from './components/AdminMode/AdminMode';

const App = () => {
    let pathParts = decodeURIComponent(window.location.pathname)
        .replace(/^\//, '')
        .replace('//$/', '')
        .split('/');
    let productSlug = decodeURIComponent(pathParts[0]).replace(/\s/g, '-');
    if (!productSlug) {
        productSlug = 'casper';
    }
    let adminMode = pathParts[1] === 'admin' ?? false;

    const [product, setProduct] = useState(null);
    const [frameImages, setFrameImages] = useState(null);
    const [frameImagesLoaded, setFrameImagesLoaded] = useState(0);
    const [frameIndex, setFrameIndex] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [showDragTooltip, setShowDragTooltip] = useState(false);
    const [showHotspotDetails, setShowHotspotDetails] = useState(false);
    const [activeHotspot, setActiveHotspot] = useState(null);
    const [productQuickViewItems, setProductQuickViewItems] = useState([]);
    const [error, setError] = useState(null);
    const [adminText, setAdminText] = useState(null);
    const [hotspotsEnabled, setHotspotsEnabled] = useState(true);
    const [animationInterval, setAnimationInterval] = useState(null);
    const stateRef = useRef();

    const clickHotspot = hotspot => {
        if (hotspot.id === activeHotspot?.id) {
            setTimeout(() => {
                setActiveHotspot(null);
            }, 300);
            setShowHotspotDetails(false);
        } else {
            setActiveHotspot(hotspot);
            setShowHotspotDetails(true);
        }
    };

    const animateTo = (index, callback) => {
        let nextIndex = frameIndex;
        let totalFrames = frameImages.length;
        let forward = frameIndex - index > totalFrames / 2;

        let animationInterval = setInterval(() => {
            nextIndex += forward ? 1 : -1;
            if (nextIndex === totalFrames) {
                nextIndex = 0;
            } else if (nextIndex === -1) {
                nextIndex = totalFrames - 1;
            }
            setFrameIndex(nextIndex);
            if (nextIndex === index) {
                clearInterval(animationInterval);
                setAnimationInterval(null);
                callback();
            }
        }, 500 / totalFrames);

        if (index === frameIndex) {
            return;
        } else if (animationInterval) {
            clearInterval(animationInterval);
            setAnimationInterval(null);
        }

        setAnimationInterval(animationInterval);
    };

    const openModal = () => {
        setModalOpen(true);
        setShowDragTooltip(true);
        setTimeout(() => setShowDragTooltip(false), 3000);
        if (window.top) {
            window.top.postMessage('enter-360', '*');
        }
    };

    const closeModal = () => {
        setModalOpen(false);
        if (window.top) {
            window.top.postMessage('exit-360', '*');
        }
    };

    useEffect(() => {
        if (!product) {
            getProduct(productSlug)
                .then(product => {
                    product?.frames.sort((a, b) =>
                        a.file.fileName.localeCompare(b.file.fileName)
                    );
                    product.frames = product?.frames.map(
                        frame => frame.file.url + '?fm=jpg&q=90'
                    );
                    setProduct(product);
                    setFrameIndex(0);
                    let productQuickViewItems = [];
                    if (!product.hotspots) {
                        product.hotspots = [];
                    }
                    product.hotspots.forEach((hotspot, hotspotIndex) => {
                        if (!hotspot.frames) {
                            return;
                        }
                        hotspot.frames = hotspot.frames.map(frame =>
                            parseInt(frame)
                        ); // convert frame indexes to ints
                        if (
                            productQuickViewItems.filter(
                                item =>
                                    hotspot.hotspotDetail.id ===
                                    product.hotspots[item.hotspotIndex]
                                        .hotspotDetail.id
                            ).length > 0
                        ) {
                            return; // dont show same hotspot detail twice in quickview
                        }
                        productQuickViewItems.push({
                            name: hotspot.hotspotDetail.title,
                            frameIndex: hotspot.frames[0],
                            hotspotIndex,
                            image:
                                (hotspot.hotspotDetail.thumbnail
                                    ? hotspot.hotspotDetail.thumbnail.file.url
                                    : hotspot.hotspotDetail.images &&
                                      hotspot.hotspotDetail.images.length > 0
                                    ? hotspot.hotspotDetail.images[0].file.url
                                    : '') + '?w=100',
                        });
                    });
                    setProductQuickViewItems(productQuickViewItems);

                    let frameImages = {};
                    let loaded = 0;
                    let loadFrame = (frame, tries) => {
                        if (frame.image || tries > 10) {
                            return;
                        }
                        setTimeout(() => {
                            let image = new Image();
                            image.src = frame;
                            image.onload = () => {
                                loaded++;
                                setFrameImagesLoaded(current => current + 1);
                                if (loaded === product.frames.length) {
                                    setFrameImages(
                                        product.frames.map(
                                            frame => frameImages[frame]
                                        )
                                    );
                                }
                            };
                            image.onerror = () => loadFrame(frame, tries + 1);
                            frameImages[frame] = image;
                        }, tries * 100);
                    };

                    product.frames.forEach(frame => loadFrame(frame, 0));
                })
                .catch(err => {
                    console.log(err);
                    setError('Error loading product');
                });
        }
    });

    if (error) {
        return <div>{error}</div>;
    } else if (
        !product ||
        frameIndex === null ||
        !frameImages ||
        frameImagesLoaded !== frameImages.length
    ) {
        return (
            <div className={styles.loaderContainer}>
                <div className={styles.loader}>
                    <div
                        className={styles.progress}
                        style={{
                            width: `${
                                frameImages
                                    ? (frameImagesLoaded / frameImages.length) *
                                      100
                                    : 0
                            }%`,
                        }}
                    />
                </div>
            </div>
        );
    }

    return (
        <div
            className={styles.coverImage}
            style={{ backgroundImage: `url(${product.coverImage.file.url})` }}
        >
            <Modal onClose={closeModal} open={modalOpen}>
                {adminMode && (
                    <AdminMode
                        frameIndex={frameIndex}
                        hotspotsEnabled={hotspotsEnabled}
                        toggleHotspots={() =>
                            setHotspotsEnabled(!hotspotsEnabled)
                        }
                        text={adminText}
                    />
                )}
                <FrameViewer
                    frame={frameImages[frameIndex]}
                    frameIndex={frameIndex}
                    totalFrames={frameImages.length}
                    adminMode={adminMode}
                    setAdminText={text => setAdminText(text)}
                    hotspots={hotspotsEnabled ? product.hotspots : []}
                    activeHotspot={activeHotspot}
                    clickHotspot={clickHotspot}
                    hideDragTooltip={() => setShowDragTooltip(false)}
                    setFrameIndex={index => {
                        if (animationInterval) {
                            clearInterval(animationInterval);
                            setAnimationInterval(null);
                        }
                        setFrameIndex(index);
                    }}
                />
                <DragTooltip show={showDragTooltip} />
                <HotspotDetails
                    onClose={() => {
                        setShowHotspotDetails(false);
                        setActiveHotspot(null);
                    }}
                    show={showHotspotDetails}
                    body={activeHotspot?.hotspotDetail?.body ?? ''}
                    title={activeHotspot?.hotspotDetail?.title ?? ''}
                    thumbnail={activeHotspot?.hotspotDetail?.thumbnail ?? null}
                    links={activeHotspot?.hotspotDetail?.links ?? []}
                    resources={activeHotspot?.hotspotDetail?.resources ?? []}
                    footnotes={activeHotspot?.hotspotDetail?.footnotes ?? []}
                    images={activeHotspot?.hotspotDetail?.images ?? []}
                />
                {product.hotspots.length > 0 && (
                    <QuickView
                        items={productQuickViewItems}
                        onChooseItem={(frameIndex, hotspotIndex) => {
                            setShowHotspotDetails(false);
                            setActiveHotspot(null);
                            animateTo(frameIndex, () => {
                                clickHotspot(product.hotspots[hotspotIndex]);
                            });
                        }}
                    />
                )}
            </Modal>
            <Button onClick={openModal}>Launch Viewer</Button>
        </div>
    );
};

export default App;
