import React from 'react';
import PropTypes from 'prop-types';

import styles from './Close.module.scss';

const Close = ({ onClick }) => (
    <button className={styles.button} onClick={onClick} />
);

Close.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default Close;
